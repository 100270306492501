// Environment specific config
export const DEFAULT_LOGIN_DEST = "/authentication/";
export const APPLY_EPPS_LOCKDESK_SETTINGS =
  process.env.REACT_APP_APPLY_EPPS_LOCKDESK_SETTINGS === "true";
export const PRICING_DETAILS =
  process.env.REACT_APP_SHOW_PRICING_DETAILS === "true";
// adapted for react-scripts
export const { REACT_APP_BASIC_TOKEN: BASIC_TOKEN } = process.env;
export const {
  REACT_APP_EPPSWIDGET_SERVER_API: EPPSWIDGET_SERVER_API,
} = process.env;
export const {
  REACT_APP_EPPSAPI_PROTO_AND_DOMAIN: EPPSAPI_PROTO_AND_DOMAIN,
} = process.env;

export const { REACT_APP_LOANCATCHER_API: EPPS_LOANCATCHER_API } = process.env;

// export const {
//   REACT_APP_API_PROTO_AND_DOMAIN: API_PROTO_AND_DOMAIN,
// } = process.env;
export const {
  REACT_APP_BP_PROTO_AND_DOMAIN: BP_PROTO_AND_DOMAIN,
} = process.env;
export const DEBUG_BUYSIDE = process.env.REACT_APP_DEBUG_BUYSIDE === "true";
export const DATA_SOURCE_BUYSIDE =
  "urn:elli:services:form:secondarylock:getbuysidepricing";
export const DATA_SOURCE_SELLSIDE =
  "urn:elli:services:form:secondarylock:getsellsidepricing";
export const { REACT_APP_EM_CLIENT_ID: EM_CLIENT_ID } = process.env;
export const { REACT_APP_RESPONSE_TYPE: RESPONSE_TYPE } = process.env;
export const { REACT_APP_EM_SCOPE: EM_SCOPE } = process.env;
export const { REACT_APP_PA_VIEW: PA_VIEW } = process.env;
export const { REACT_APP_EPPSAPI_VERSION: EPPSAPI_VERSION } = process.env;
export const {
  REACT_APP_EPPSWIDGET_SERVER_VERSION: EPPSWIDGET_SERVER_VERSION,
} = process.env;
export const { REACT_APP_ENV_NAME: ENV_NAME } = process.env;
// Configs for all envs
export const PUBLIC_PATH =
  process.env.NODE_ENV === "development" ? "http://localhost:3001/" : "/";
export const AUTH_TOKEN = "access_token";
export const STORAGE_PREFIX = "EM_EPPS";
export const TOKEN_KEY = `${STORAGE_PREFIX}-token`;
export const PAT_TOKEN_KEY = `${STORAGE_PREFIX}-PATtoken`;
export const TRANS_ID_KEY = `${STORAGE_PREFIX}-TRANSID`;
export const JWT_TOKEN_KEY = `${STORAGE_PREFIX}-JWTtoken`;
export const USER_TYPE_KEY = `${STORAGE_PREFIX}-UserType`;
export const BORROWER_ID_KEY = `${STORAGE_PREFIX}-BorrowerID`;
export const WEBHOOKTOKEN_KEY = `${STORAGE_PREFIX}-Webhooktoken`;
export const HOSTAPPLICATION_KEY = `${STORAGE_PREFIX}-HostApplication`;
export const ORIGIN_ID_KEY = `${STORAGE_PREFIX}-ORIGINID`;
export const CRED_KEY = `${STORAGE_PREFIX}-Credentials`;
export const SUMMARY_PARAMS = `${STORAGE_PREFIX}-Summary`;
export const GRANT_AND_SCOPE = `grant_type=client_credentials&scope=${encodeURIComponent(
  "pc pcapi"
)}`;
export const SESSION_SECONDS_OK = 900;
export const SESSION_SECONDS_WARN = 60;
export const SESSION_SECONDS_EXPIRE = 0;
export const SESSION_STATE_OK = 0;
export const SESSION_STATE_WARN = 1;
export const SESSION_STATE_EXPIRE = 2;
export const SESSION_STATE_LOGOUT = 3;
export const SESSION_STATE_CREATE_ACCT = 4;
export const SESSION_SAVE_CHANGES =
  "You have unsaved changes. Are you sure you want to navigate away?";
export const OAPI_SESSION_SECONDS_TOKEN_REFRESH_INTERVAL = 600; // 10 minutes
export const TOUCH_ENABLED =
  "ontouchstart" in window || navigator.msMaxTouchPoints > 0;
